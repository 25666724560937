<div class="widget" data-cy="teamplay-widget">
  <div class="widget__header">
    <p class="widget__title" [translate]="'TEAMPLAY_DASHBOARD_WIDGET_TITLE'"></p>
    <div hlTooltip [title]="'TEAMPLAY_WIDGET_BUTTON_INFO' | translate" [documentOverlay]=true [showTooltipOnTouchScreen]="true">
      <button class="button button-no-pointer-change button--control button--small icon-information pointer-events-none"></button>
    </div>

  </div>

  <hl-progress-indicator class="spinner-top-none" [loading]="!isLoaded"></hl-progress-indicator>
  @if (isLoaded) {
    <div>
      <ul class="legend legend--combo legend--combo-top display-block padding-left-0">
        @for (legend of pieChartData; track legend) {
          <li class="legend__item">
            <span class="legend__value padding-left-tiny-5" [ngStyle]="{'background-color': legend.color}"> </span>
            <span class="legend__label"><small>{{ legend.modalityName }}</small></span>
          </li>
        }
      </ul>
    </div>
  }

  @if (isLoaded) {
    <div class="fixed-flex-layout__fixed-container">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="widget widget__background_grey">
            @if (isLoaded && diagramData.length == 0) {
              <div class="alert__body" [translate]="'TEAMPLAY_DATA_NOT_AVAILABLE'"></div>
            }
            @if (isLoaded && diagramData.length > 0) {
              <p class="widget__title display-flex justify-content-center">
                <a [href]="teamplayUrl" [translate]="'TITLE_TEAMPLAY_EXAMS_NUMBER_WIDGET'" target="_blank" rel="noopener noreferrer"></a>
              </p>
              <hl-diagram-doughnut [backgroundColor]="'black-7'" [hideLegends]="true" [total]="totalCount" [totalRouterLink]="'/teamplay'"
                [labels]="false" [dataInput]="diagramData" [disableItemsClick]="true"></hl-diagram-doughnut>
            }
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="widget widget__background_grey">
            @if (isLoaded && diagramData.length == 0) {
              <div class="alert__body" [translate]="'TEAMPLAY_DATA_NOT_AVAILABLE'"></div>
            }
            @if (isLoaded && barChartData.length > 0) {
              <div>
                <p class="widget__title display-flex justify-content-center">
                  <a [href]="teamplayUrl" [translate]="'TITLE_TEAMPLAY_EXAMS_DURATION_WIDGET'" target="_blank" rel="noopener noreferrer"></a>
                </p>
                <hl-widget-diagram-bars style="width: inherit;  height: inherit;" [results]="barChartData" [scheme]="{ selectable: false, group: 'linear',
                  domain: ['#ec6602']}" [showXAxis]="false" [showYAxis]="true" [gradient]="false" [showXAxisLabel]="false" [showYAxisLabel]="false"
                  [customColors]="customColors" [animations]="true" [yScaleMax]="maxValue"
                  (onSelect)="redirectToEquipment($event)"></hl-widget-diagram-bars>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  }
  @if (isLoaded) {
    <div>
      {{ 'TEAMPLAY_WIDGET_INFO' | translate }}
    </div>
  }
</div>

